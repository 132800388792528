import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'slide-one',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'attorney-list',
    loadChildren: () => import('./attorney-list/attorney-list.module').then( m => m.AttorneyListPageModule)
  },
  {
    path: 'attorney-profile',
    loadChildren: () => import('./attorney-profile/attorney-profile.module').then( m => m.AttorneyProfilePageModule)
  },
  {
    path: 'attorney-filter',
    loadChildren: () => import('./attorney-filter/attorney-filter.module').then( m => m.AttorneyFilterPageModule)
  },
  {
    path: 'attorney-appointment',
    loadChildren: () => import('./attorney-appointment/attorney-appointment.module').then( m => m.AttorneyAppointmentPageModule)
  },
  {
    path: 'attorney-appointment-response',
    // eslint-disable-next-line max-len
    loadChildren: () => import('./attorney-appointment-response/attorney-appointment-response.module').then( m => m.AttorneyAppointmentResponsePageModule)
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./contact-us/contact-us.module').then( m => m.ContactUsPageModule)
  },
  {
    path: 'my-profile',
    loadChildren: () => import('./my-profile/my-profile.module').then( m => m.MyProfilePageModule)
  },
  {
    path: 'appointment-list',
    loadChildren: () => import('./appointment-list/appointment-list.module').then( m => m.AppointmentListPageModule)
  },
  {
    path: 'about-us',
    loadChildren: () => import('./about-us/about-us.module').then( m => m.AboutUsPageModule)
  },
  {
    path: 'client-reviews',
    loadChildren: () => import('./client-reviews/client-reviews.module').then( m => m.ClientReviewsPageModule)
  },
  {
    path: 'terms',
    loadChildren: () => import('./terms/terms.module').then( m => m.TermsPageModule)
  },
  {
    path: 'documents',
    loadChildren: () => import('./documents/documents.module').then( m => m.DocumentsPageModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./change-password/change-password.module').then( m => m.ChangePasswordPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./account/sign-up/sign-up.module').then( m => m.SignUpPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./account/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'slider-screen',
    loadChildren: () => import('./slider-screen/slider-screen.module').then( m => m.SliderScreenPageModule)
  },
  {
    path: 'slide-one',
    loadChildren: () => import('./slides/slide-one/slide-one.module').then( m => m.SlideOnePageModule)
  },
  {
    path: 'slide-two',
    loadChildren: () => import('./slides/slide-two/slide-two.module').then( m => m.SlideTwoPageModule)
  },
  {
    path: 'slide-three',
    loadChildren: () => import('./slides/slide-three/slide-three.module').then( m => m.SlideThreePageModule)
  },
  {
    path: 'slide-four',
    loadChildren: () => import('./slides/slide-four/slide-four.module').then( m => m.SlideFourPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
